import React from 'react';
import { ProductInfo } from '../../api/products';
import DetailsModal from './DetailsModal';

interface Props {
  modalOpen: boolean,
  setModalOpen: (input: boolean) => void,
  product: ProductInfo,
}

const DetailsModalContainer = (prop: Props) => {
  return <DetailsModal
    modalOpen={prop.modalOpen}
    setModalOpen={prop.setModalOpen}
    product={prop.product}
  />
}

export default DetailsModalContainer;