import React, { useEffect, useState } from 'react';
import { GemInfo, getGems } from '../../api/gems';
import { getTagByCode, TagInfo } from '../../api/tags';
import Gems, { EnrichedGemInfo } from './Gems';

const enrichGem = async (gem: GemInfo): Promise<EnrichedGemInfo> => {
  const tags = await getGemTags(gem.tags);
  return Object.assign({}, gem, { tags }) as EnrichedGemInfo
}

const getGemTags = (tags: Array<string>): Promise<Array<TagInfo>> => {
  return Promise.all(tags.map(t => getTagByCode(t)));
}

const GemsContainer = () => {
  const [gemsInfo, setGemInfo] = useState<Array<EnrichedGemInfo>>([]);
  useEffect(() => {
    const f = async () => {
      const gems = await getGems();
      const enrichedGems = await Promise.all(gems.items.map(g => enrichGem(g)));
      setGemInfo(enrichedGems);
    }
    f();
  }, []);

  return (<Gems gemsInfo={gemsInfo} />);
};

export default GemsContainer;
