import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

interface Props {
  children: React.ReactNode,
  variant?: 'main',
}

const useStyles = makeStyles(({ spacing, palette }: Theme) => createStyles({
  common: {
    maxWidth: 1170,
    padding: spacing(1),
    margin: 'auto',
    overflow: 'hidden',
    marginBottom: spacing(2),
    backgroundColor: palette.primary.light,
  },
  main: {
    height: '90vh',
    maxWidth: '100%',
    padding: 0,
    marginBottom: spacing(3),
  },
}));

const Section = ({ children, variant }: Props) => {
  const classes = useStyles();
  return (
    <Paper className={`${classes.common} ${variant === 'main' ? classes.main : ''}`}>
      {children}
    </Paper>
  )
};

export default Section;
