import React, { useContext } from 'react';
import { context as isMobileContext } from '../IsMobileProvider';
import DesktopSection from './DesktopSection';

interface Props {
  children: React.ReactNode,
}

const DesktopSectionContainer = ({ children }: Props) => {
  const isMobile = useContext(isMobileContext);
  return (
    <DesktopSection isDesktop={!isMobile}>
      {children}
    </DesktopSection>
  );
}

export default DesktopSectionContainer;
