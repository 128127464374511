import { MenuItem } from "../component/AppBar/AppBarContainer";
import configData from "./data/config.json";

export type AppbarConfigType = {
  menuItems: Array<MenuItem>,
  socialItems: Array<MenuItem>,
};

export const getAppbarConfig = async () :Promise<AppbarConfigType>=> {
  return configData as AppbarConfigType;
};
