import BugReportIcon from '@material-ui/icons/BugReport';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import FacebookIcon from '@material-ui/icons/Facebook';
import HomeIcon from '@material-ui/icons/Home';
import InstagramIcon from '@material-ui/icons/Instagram';
import MenuIcon from '@material-ui/icons/Menu';
import MoodIcon from '@material-ui/icons/Mood';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SearchIcon from '@material-ui/icons/Search';
import SecurityIcon from '@material-ui/icons/Security';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import WavesIcon from '@material-ui/icons/Waves';
import WorkIcon from '@material-ui/icons/Work';
import React, { Fragment } from 'react';


export type IconVariant = 'joy' | 'protection' | 'career' | 'calm' | 'courage' | 'facebook' | 'instagram' | 'home' | 'gem' | 'close' | 'description' | 'search' | 'menu' | 'prev' | 'next' | 'moreVert';

interface Props {
  variant?: IconVariant,
  className?: string,
}

const renderIcon = (variant?: IconVariant, className?: string) => {
  switch (variant) {
    case 'joy':
      return <MoodIcon className={className} />;
    case 'protection':
      return <SecurityIcon className={className} />;
    case 'career':
      return <WorkIcon className={className} />;
    case 'calm':
      return <WavesIcon className={className} />;
    case 'courage':
      return <BugReportIcon className={className} />;
    case 'facebook':
      return <FacebookIcon className={className} />;
    case 'instagram':
      return <InstagramIcon className={className} />;
    case 'home':
      return <HomeIcon className={className} />;
    case 'gem':
      return <SportsSoccerIcon className={className} />;
    case 'close':
      return <CloseIcon className={className} />;
    case 'description':
      return <DescriptionIcon className={className} />;
    case 'search':
      return <SearchIcon className={className} />;
    case 'menu':
      return <MenuIcon className={className} />;
    case 'prev':
      return <NavigateBeforeIcon className={className} />;
    case 'next':
      return <NavigateNextIcon className={className} />;
    case 'moreVert':
      return <MoreVertIcon className={className} />;
    default:
      return undefined;
  }
}

const Icon = ({ variant, className }: Props) => (
  <Fragment>
    {renderIcon(variant, className)}
  </Fragment>
);

export default Icon;
