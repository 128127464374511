import { createStyles, makeStyles, Modal, Paper, Theme, Typography } from '@material-ui/core';
import React from 'react';

import { ProductInfo } from '../../api/products';
import TextBlock from '../TextBlock';

interface Props {
  modalOpen: boolean,
  setModalOpen: (input: boolean) => void,
  product: ProductInfo,
}

const useStyles = makeStyles((_: Theme) => createStyles({
  root: {
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    width: '80%',
    bgcolor: 'background.paper',
    border: '4px solid #000',
    padding: '4px',
    boxShadow: '24px',
    borderRadius: '8px',
    p: 4,
  },
}));

const DetailsModal = ({ modalOpen, setModalOpen, product }: Props) => {
  const classes = useStyles();

  return (
    <Modal open={modalOpen} onClose={() => { setModalOpen(false) }}>
      <Paper className={classes.modal}>
        <img src={product.imageUrl} style={{ position: 'relative', width: '100%', top: 0, overflow: 'clip' }} alt={`${product.type} - ${product.name} design`} />
        <Typography variant="h6" component="h2">
          {product.name}
        </Typography>
        <TextBlock variant="body2" component="p" text={product.description} />
      </Paper>
    </Modal>
  )
}

export default DetailsModal;
