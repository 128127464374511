import { IconVariant } from "../component/Icon";
import tagData from './data/tags.json';

export type TagInfo = {
  code: string,
  label: string,
  icon?: IconVariant,
};

export const getTagByCode = async (code: string): Promise<TagInfo> => {
  return tagData.find(ti => ti.code === code) as TagInfo;
}