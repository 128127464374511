import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Axios from 'axios';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AppBar from './component/AppBar';
import IsMobileProvider from './component/IsMobileProvider';
import Gems from './pages/Gems';
import LandingPage from './pages/Landing';
import ProductsPage from './pages/Products';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#e5dae4',
      main: '#d4c9d3',
      dark: '#b3b8c2',
      contrastText: '#000',
    },
    secondary: {
      light: '#ffffff',
      main: '#ffd9e4',
      dark: '#cca7b2',
      contrastText: '#000',
    },
  },
  typography: {
    fontFamily: [
      'Merienda',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const App = () => {
  Axios.defaults.baseURL = process.env.REACT_APP_API_ROOT;
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <IsMobileProvider>
          <AppBar />
          <Switch>
            <Route path="/gems">
              <Gems />
            </Route>
            <Route path="/products">
              <ProductsPage />
            </Route>
            <Route path="/">
              <LandingPage />
            </Route>
          </Switch>
        </IsMobileProvider>
      </ThemeProvider>
    </Router>
  )
};

export default App;