import React, { useContext } from 'react';
import { context as isMobileContext } from '../IsMobileProvider';
import MobileSection from './MobileSection';

interface Props {
  children: React.ReactNode,
}

const MobileSectionContainer = ({ children }: Props) => {
  const isMobile = useContext(isMobileContext);
  return (
    <MobileSection isMobile={isMobile}>
      {children}
    </MobileSection>
  );
}

export default MobileSectionContainer;
