import React, { Fragment, useEffect, useState } from 'react';
import { getProductsByIds, ProductInfo as ProductInfoType } from '../../api/products';
import ProductCard from './ProductCard';

interface Props {
  product: ProductInfoType,
}

const ProductCardContainer = ({ product }: Props) => {
  const [showDetails, setShowDetails] = useState(false);
  const [productList, setProductList] = useState([product]);

  const toggleShowDetails = () => setShowDetails(!showDetails);
  const goToUrl = (routeUrl: string) => window.location.href = routeUrl;

  const [pIdx, setPIdx] = useState(0);

  useEffect(() => {
    const callApi = async () => {
      const collectionList = await getProductsByIds(product.collection);
      setProductList(p => [...p, ...collectionList]);
    }
    if (product.isCollection) callApi();
  }, [product.isCollection, product.collection])

  return (
    <Fragment>
      <ProductCard
        showDetails={showDetails}
        variant="instagram"
        toggleShowDetails={toggleShowDetails}
        action={goToUrl}
        collection={productList}
        setIdx={setPIdx}
        idx={pIdx}
      />
    </Fragment>
  );
}

export default ProductCardContainer;
