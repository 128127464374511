import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Icon, { IconVariant } from '../../../../component/Icon';

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => createStyles({
  root: {
    position: 'absolute' as 'absolute',
    bottom: spacing(6),
    left: spacing(12),
    maxWidth: spacing(60),
    backgroundColor: '#FFFFFFAA',
    padding: spacing(2),
    [breakpoints.down('md')]: {
      left: spacing(4),
      right: spacing(4),
    },
  },
  nameLogo: {
    height: 24,
  },
}));

interface Props {
  info: {
    title: string,
    description: string,
    callToAction: {
      href: string,
      text: string,
      icon?: IconVariant,
    },
  },
}


const InfoOverlay = (({ info }: Props) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root} elevation={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img className={classes.nameLogo} src='https://storage.googleapis.com/gleamxgem.appspot.com/images/gleamxgem.svg' alt="GleamxGem" />
          <Typography variant="body1">{info.description}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={() => window.location.href = info.callToAction.href}>
            {info.callToAction.text}{Boolean(info.callToAction.icon) ? <Icon variant={info.callToAction.icon} /> : undefined}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
});

export default InfoOverlay;
