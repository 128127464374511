import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import React from 'react';

interface Props {
  variant: Variant,
  component: React.ElementType,
  texts: Array<string>,
}

const useStyles = makeStyles(({ spacing }: Theme) => createStyles({
  blocks: {
    marginBottom: spacing(1),
  }
}));

const TextBlock = ({ variant, component, texts }: Props) => {
  const classes = useStyles();

  return (
    <div>
      {texts.map(t => (
        <Typography className={classes.blocks} variant={variant} component={component}>
          {t}
        </Typography>
      ))}
    </div>
  );
}

export default TextBlock;
