import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getAppbarConfig } from '../../api/config';
import { IconVariant } from '../Icon';
import AppBar from './AppBar';

export type MenuItem = {
  name: string,
  icon?: IconVariant,
  path: string,
}

const AppBarContainer = () => {
  const isScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const [selectedTabIdx, setSelectedTabIdx] = useState<number>(0);
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState<Array<MenuItem>>([]);
  const [socialItems, setSocialItems] = useState<Array<MenuItem>>([]);

  useEffect(() => {
    const f = async () => {
      const res = await getAppbarConfig();
      setMenuItems(res.menuItems);
      setSocialItems(res.socialItems);
    };

    f();
  }, [])

  const history = useHistory();
  const handleTabSelection = (_: React.ChangeEvent<{}>, tabId: number) => {
    setSelectedTabIdx(tabId);
    const pathname = menuItems[tabId]?.path;
    if (!!pathname && pathname !== history.location.pathname) {
      history.push(pathname);
    }
  }

  return <AppBar menuItems={menuItems} selectedTabIdx={selectedTabIdx} isScrolled={isScrolled} handleTabSelection={handleTabSelection} isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} socialItems={socialItems} />
}

export default AppBarContainer;
