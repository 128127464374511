import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { Fragment } from 'react';
import { ProductInfo as ProductInfoType } from '../../api/products';
import { PageView } from '../../api/views/page';
import FeatureSection from './section/FeatureSection';
import MainSection from './section/MainSection';

const useStyles = makeStyles(({ palette, spacing, breakpoints }: Theme) => createStyles({
  header: {
    flex: 1,
    textAlign: 'center',
  },
  productSection: {
    backgroundColor: 'inherits',
  },
  productGrid: {
    margin: 0,
    width: 'auto',
  },
  productStepper: {
    marginTop: spacing(-2),
    backgroundColor: 'inherit',
    [breakpoints.down('xs')]: {
      marginTop: spacing(-0.5),
    }
  }
}));

interface Props {
  featuredProducts?: PageView<ProductInfoType>,
  productCollections?: PageView<ProductInfoType>,
}

const LandingPage = ({ featuredProducts, productCollections }: Props) => {
  const classes = useStyles();

  return (
    <Fragment>
      <MainSection />
      {featuredProducts && featuredProducts.items.length > 0 ? <FeatureSection title="Featured Products" products={featuredProducts.items} classes={classes} /> : null}
      {productCollections !== undefined && productCollections.items.length > 0 ? <FeatureSection title="Collections" products={productCollections.items} classes={classes} /> : null}
    </Fragment>
  );
};

export default LandingPage;
