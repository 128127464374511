import React, { useEffect, useState } from 'react';
import { getCollections, getFeaturedProducts, ProductInfo as ProductInfoType } from '../../api/products';
import { PageView } from '../../api/views/page';
import LandingPage from './LandingPage';

const LandingPageContainer = () => {
  const [featuredProducts, setFeaturedProducts] = useState<PageView<ProductInfoType> | undefined>(undefined);
  const [productCollections, setProductCollections] = useState<PageView<ProductInfoType> | undefined>(undefined);
  useEffect(() => {
    const callApi = async (f: () => Promise<PageView<ProductInfoType>>, setState: React.Dispatch<React.SetStateAction<PageView<ProductInfoType> | undefined>>) => {
      setState(await f());
    }
    callApi(getFeaturedProducts, setFeaturedProducts);
    callApi(getCollections, setProductCollections);
  }, [])

  return (
    <LandingPage featuredProducts={featuredProducts} productCollections={productCollections} />
  );
}

export default LandingPageContainer;
