import React, { useContext, useEffect, useState } from 'react';

import { getProducts, ProductInfo } from '../../api/products';
import { context as isMobileContext } from '../../component/IsMobileProvider';
import ProductsPage from './ProductsPage'

interface Props {
}

const ProductsPageContainer = (_: Props) => {
    const [productsInfo, setProductsInfo] = useState<Array<ProductInfo>>([]);
    const isMobile = useContext(isMobileContext);
    useEffect(() => {
        const f = async () => {
            const res = await getProducts(p => !p.isCollection);
            setProductsInfo(res.items);
        };

        f();
    }, []);

    return (
        <ProductsPage productsInfo={productsInfo} isMobile={isMobile} />
    )
};

export default ProductsPageContainer;
