import { PageView } from "./views/page";
import gemData from "./data/gems.json";

export type GemInfo = {
  imgUrl?: string,
  description: string,
  tags: Array<string>,
  name: string,
}

export const getGems = async (): Promise<PageView<GemInfo>> => {
  return {
    items: gemData.items
      .sort((a, b) => a.name > b.name ? 1 : -1),
    hasMore: false
  }
};