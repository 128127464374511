import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import TokenSetContext from './context';

interface Props {
  children: React.ReactNode,
};

const TokenSetProvider = ({ children }: Props) => (
  <TokenSetContext.Provider value={useMediaQuery('(max-width: 760px)')}>
    {children}
  </TokenSetContext.Provider>
)

export default TokenSetProvider;
