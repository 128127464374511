import { IconVariant } from "../component/Icon";
import introData from "./data/intro.json";

export type Intro = {
  title: string
  description: string
  bannerUrl: {
    bg: string,
    sm: string
  },
  callToAction: {
    text: string,
    href: string,
    icon?: IconVariant,
  }
};

export const getIntro = async ():Promise<Intro> => {
  return introData;
};
