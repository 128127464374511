import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { TagInfo } from '../../api/tags';
import GemCard from '../../component/GemCard';
import Section from '../../component/Section';

export type EnrichedGemInfo = {
  imgUrl?: string,
  description: string,
  tags?: Array<TagInfo>,
  name: string,
}

interface Props {
  gemsInfo: Array<EnrichedGemInfo>,
}

const useStyles = makeStyles(({ spacing }: Theme) => createStyles({
  root: {
    minHeight: '100vh',
    width: '100%',
  },
  content: {
    paddingTop: 64,
  },
  header: {
    flex: 1,
    textAlign: 'center',
  },
}));

const Gems = ({ gemsInfo }: Props) => {
  const classes = useStyles();

  const renderGridItems = () => (
    <Grid container spacing={2}>
      {gemsInfo
        .map(g => (
          <Grid key={`gem-${g.name}`} item md={6}>
            <GemCard {...g} />
          </Grid>
        ))}
    </Grid>
  );

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Section>
          <Typography className={classes.header} variant="h4">Gems</Typography>
          <Typography variant="subtitle1">Natural Gemstones are not only beautiful to look at, they are also reputed to be able to produce energies that the wearer will be able to take advantage of. Below are the types of stones that we carry and their properties. Using these information, we are able to customise accessories according to your requirements.</Typography>
        </Section>
        <Section>
          {renderGridItems()}
        </Section>
      </div>
    </div>
  )
};

export default Gems;
