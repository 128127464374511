import React from 'react';
import { TagInfo } from '../../api/tags';
import GemCard from './GemCard';

interface Props {
  name: string,
  description: string,
  imgUrl?: string,
  tags?: Array<TagInfo>,
}

const GemCardContainer = (props: Props) => (
  <GemCard {...props} />
);

export default GemCardContainer;
