import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { IconVariant } from '../../../../component/Icon';
import Section from '../../../../component/Section';
import InfoOverlay from './InfoOverlay';

interface Props {
  bannerUrl?: string,
  info: {
    title: string,
    description: string,
    callToAction: {
      href: string,
      text: string,
      icon?: IconVariant,
    },
  }
}

const useStyles = makeStyles((_: Theme) => createStyles({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  banner: {
    position: 'absolute',
    objectFit: 'cover',
    minHeight: '100%',
    minWidth: '100%',
  }
}));

const MainSection = ({ bannerUrl, info }: Props) => {
  const classes = useStyles();
  return (
    <Section variant="main">
      <div className={classes.root}>
        <img className={classes.banner} src={bannerUrl} alt="banner" />
        <InfoOverlay info={info} />
      </div>
    </Section>
  );
}

export default MainSection;
