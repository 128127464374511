import React, { useContext, useEffect, useState } from 'react';
import { getIntro, Intro as IntroType } from '../../../../api/intro';
import { context as isMobileContext } from '../../../../component/IsMobileProvider';
import MainSection from './MainSection';

const MainSectionContainer = () => {
  const isMobile = useContext(isMobileContext);
  const [intro, setIntro] = useState<IntroType | undefined>(undefined)

  useEffect(() => {
    const f = async () => { setIntro(await getIntro()); }
    f();
  }, []);

  if (intro !== undefined) {
    return <MainSection bannerUrl={isMobile ? intro.bannerUrl.sm : intro.bannerUrl.bg} info={intro} />
  }

  return null;
}

export default MainSectionContainer;
