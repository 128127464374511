import { Drawer, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import MuiAppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import DesktopSection from '../DesktopSection';
import Icon from '../Icon';
import MobileSection from '../MobileSection';
import { MenuItem as MenuItemType } from './AppBarContainer';

interface Props {
  isScrolled?: boolean,
  handleTabSelection: (_: React.ChangeEvent<{}>, tabId: number) => void,
  selectedTabIdx?: number,
  isMenuOpen: boolean,
  setMenuOpen: (newValue: boolean) => void,
  menuItems: Array<MenuItemType>,
  socialItems: Array<MenuItemType>,
};

const useStyles = makeStyles(({ breakpoints }: Theme) => createStyles({
  root: {
    background: '#FFFFFFAA',
  },
  title: {
    height: 24,
    textAlign: 'center',
    flex: 1,
    [breakpoints.up('md')]: {
      marginLeft: 0,
      marginRight: 24,
      textAlign: 'left',
      flex: 'none',
    },
  },
  nameLogo: {
    height: '100%'
  },
  menu: {
    width: 250,
  },
  menuItem: {
    width: 'auto',
  },
  socialMenuText: {
    width: 135,
  },
}));

const AppBar = ({ menuItems, selectedTabIdx, isScrolled, handleTabSelection, isMenuOpen, setMenuOpen, socialItems }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MuiAppBar className={`${isScrolled ? "" : classes.root}`} elevation={isScrolled ? 4 : 0} color={isScrolled ? "primary" : "transparent"} >
      <Toolbar>
        <MobileSection>
          <IconButton onClick={() => setMenuOpen(true)} edge="start">
            <Icon variant="menu" />
          </IconButton>
          <Drawer anchor="left" open={isMenuOpen} onClose={() => setMenuOpen(false)} >
            <List className={classes.menu}>
              {Boolean(menuItems) ? menuItems.map((mi, idx) => (
                <ListItem
                  className={classes.menuItem}
                  key={mi.name}
                  button
                  onClick={(evt) => {
                    handleTabSelection(evt, idx);
                    setMenuOpen(false);
                  }}>
                  <ListItemIcon><Icon variant={mi.icon} /></ListItemIcon>
                  <ListItemText primary={mi.name} />
                </ListItem>
              )) : null}
            </List>
          </Drawer>
        </MobileSection>
        <div className={classes.title} onClick={(evt) => handleTabSelection(evt, 0)}>
          <img className={classes.nameLogo} src='https://storage.googleapis.com/gleamxgem.appspot.com/images/gleamxgem.svg' alt="GleamxGem" />
        </div>
        <DesktopSection>
          <Tabs value={selectedTabIdx} onChange={handleTabSelection} style={{ flex: 1 }}>
            {menuItems?.map((mi) => (
              <Tab key={mi.name} label={mi.name} style={mi.name === 'Home' ? { display: 'none' } : {}} />
            ))}
          </Tabs>
          {socialItems?.map((i, idx) => (
            <IconButton key={i.name} aria-label={`social-${i.name}`} color="inherit" onClick={() => { window.location.href = i.path }} edge={idx === socialItems.length - 1 ? "end" : undefined}>
              <Icon variant={i.icon} />
            </IconButton>
          ))}
        </DesktopSection>
        <MobileSection>
          <IconButton edge="end" onClick={handleClick}>
            <Icon variant="moreVert" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {socialItems?.map((i) => (
              <MenuItem
                key={i.name}
                onClick={() => {
                  window.location.href = i.path;
                  setAnchorEl(null);
                }} aria-label={`social-${i.name}`}>
                <span className={classes.socialMenuText}>{`Visit ${i.name}`}</span><Icon variant={i.icon} />
              </MenuItem>
            ))}
          </Menu>
        </MobileSection>
      </Toolbar>
    </MuiAppBar >
  );
};

export default AppBar;
