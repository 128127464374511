import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Fragment } from 'react';
import { TagInfo } from '../../api/tags';
import Icon from '../Icon';

const useStyles = makeStyles(({ spacing, palette }: Theme) => createStyles({
  root: {
    minHeight: '100%',
    backgroundColor: palette.primary.light,
  },
  content: {
    padding: spacing(2),
  },
  header: {
    marginBottom: spacing(1),
  },
  tag: {
    margin: spacing(0.5),
  },
  dp: {
    maxWidth: '100%',
    flex: 1,
    borderColor: '#f0f0f0',
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: 'white',
  },
  info: {
  },
}));

interface Props {
  name: string,
  description: string,
  imgUrl?: string,
  tags?: Array<TagInfo>,
}

const GemCard = ({ name, description, imgUrl, tags }: Props) => {
  const classes = useStyles();

  const renderTags = () => (
    <Fragment>
      {tags?.map(tag => {
        return (
          <Chip
            key={`tag-${tag.code}`}
            className={classes.tag}
            label={tag.label}
            icon={<Icon variant={tag.icon} />}
            size="small"
          />
        );
      })}
    </Fragment>
  );

  return (
    <Paper className={classes.root}>
      <div className={classes.content}>
        <Typography className={classes.header} variant="h5" align="center">{name}</Typography>
        {renderTags()}
        <Grid container spacing={2}>
          {/* <Grid item xs={4}>
            <img className={classes.dp} src={!!imgUrl ? imgUrl : 'https://storage.googleapis.com/gleamxgem.appspot.com/images/default-gem.webp'} alt={name} />
          </Grid> */}
          <Grid item xs={12}>
            <div className={classes.info}>
              <Typography variant="body1">{description}</Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}

export default GemCard;
