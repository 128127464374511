import React, { Fragment } from 'react';

interface Props {
  isMobile: boolean | undefined,
  children: React.ReactNode,
}

const MobileSection = ({ children, isMobile }: Props) => (
  <Fragment>{isMobile ? children : undefined}</Fragment>
);

export default MobileSection;
