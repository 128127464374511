import React, { Fragment } from 'react';

interface Props {
  isDesktop: boolean | undefined,
  children: React.ReactNode,
}

const DesktopSection = ({ children, isDesktop }: Props) => (
  <Fragment>{isDesktop ? children : undefined}</Fragment>
);

export default DesktopSection;
