import { Button, MobileStepper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { ProductInfo as ProductInfoType } from '../../../../api/products';
import ProductCard from '../../../../component/ProductCard';
import Section from '../../../../component/Section';

interface Props {
  classes?: {
    header?: string;
    productGrid?: string;
    productSection?: string;
    productStepper?: string;
  };
  products: Array<ProductInfoType>;
  title: string;
  step: number;
  setStep: (newStep: number) => void;
}

interface Props {
  isMobile: boolean;
  maxItems: number;
}

const FeatureSection = ({ products, isMobile, classes, title, maxItems, step, setStep }: Props) => {
  const renderFeaturedProducts = () => (
    <Grid className={classes?.productGrid} container spacing={isMobile ? 1 : 4}>
      {products
        ?.slice(step, step + maxItems)
        .map(p => (
          <Grid key={`${title}-${p.name}`.toLowerCase().replaceAll(' ', '-')} item md={4} xs={12}>
            <ProductCard product={p} />
          </Grid>
        ))}
    </Grid>
  );

  return (
    <Section>
      <Typography className={classes?.header} variant="h4">{title}</Typography>
      <div className={classes?.productSection}>
        {renderFeaturedProducts()}
        {products.length > maxItems ? (
          <MobileStepper
            className={classes?.productStepper}
            steps={products.length - maxItems + 1}
            position="static"
            variant="text"
            activeStep={step}
            nextButton={<Button size="small" onClick={() => setStep(step + 1)} disabled={step === products.length - maxItems}>Next</Button>}
            backButton={<Button size="small" onClick={() => setStep(step - 1)} disabled={step === 0}>Back</Button>}
          />
        ) : null}
      </div>
    </Section >
  );
}

export default FeatureSection;
