import { PageView } from "./views/page";
import productData from "./data/products.json";

export type ProductInfo = {
  id: string,
  name: string,
  type: string,
  description: string,
  tags: Array<string>,
  isCollection: boolean,
  isFeatured: boolean,
  imageUrl: string,
  routeUrl: string,
  collection: Array<string>,
  collectionProduct?: Array<ProductInfo>,
};


export const getProductsByIds = async (ids: Array<string>): Promise<Array<ProductInfo>> => {
  return productData.items
    .filter(p => ids.includes(p.id));
};

export const getProducts = async (filterFunc: (p: ProductInfo) => boolean): Promise<PageView<ProductInfo>> => {
  return {
    items: productData.items
      .filter(p => filterFunc(p))
      .sort((a, b) => a.id > b.id ? 1 : -1),
    hasMore: false
  }
};

export const getFeaturedProducts = async (): Promise<PageView<ProductInfo>> => (
  getProducts(p => p.isFeatured)
);

export const getCollections = async (): Promise<PageView<ProductInfo>> => (
  getProducts(p => p.isCollection)
);