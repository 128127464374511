import React, { useContext, useState } from 'react';
import { ProductInfo as ProductInfoType } from '../../../../api/products';
import { context as isMobileContext } from '../../../../component/IsMobileProvider';
import FeatureSection from './FeatureSection';


interface Props {
  classes?: {
    header?: string;
    productGrid?: string;
    productSection?: string;
    productStepper?: string;
  };
  products: Array<ProductInfoType>;
  title: string;
}

const FeatureSectionContainer = (props: Props) => {
  const isMobile = useContext(isMobileContext);
  const [step, setStep] = useState<number>(0);

  return (
    <FeatureSection {...props} isMobile={isMobile} maxItems={isMobile ? 1 : 3} step={step} setStep={setStep} />
  )
}

export default FeatureSectionContainer;
