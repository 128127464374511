import { Variant } from '@material-ui/core/styles/createTypography';
import React from 'react';

import TextBlock from './TextBlock';

interface Props {
  variant: Variant,
  component: React.ElementType,
  text: string,
}

const TextBlockContainer = ({ variant, component, text }: Props) => {
  return (
    <TextBlock
      variant={variant}
      component={component}
      texts={text.split("\\n")}
    />
  )
}

export default TextBlockContainer;
