import React from 'react';
import { ProductInfo } from '../../api/products';
import Grid from '@material-ui/core/Grid';
import ProductCard from '../../component/ProductCard';
import Section from '../../component/Section';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles(({ spacing }: Theme) => createStyles({
    root: {
        minHeight: '100vh',
        width: '100%',
    },
    content: {
        paddingTop: 64,
    },
    header: {
        flex: 1,
        textAlign: 'center',
    },
}));

interface Props {
    productsInfo: Array<ProductInfo>;
    isMobile: boolean;
}

const ProductsPage = ({ productsInfo, isMobile }: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Section>
                    <Typography className={classes.header} variant="h4">Products</Typography>
                    <Typography variant="subtitle1">Take a look at our past masterpieces and gain inspiration for your bracelete, necklace, rings etc!</Typography>
                </Section>
                <Section>
                    <Typography className={classes.header} variant="h5">Bracelete</Typography>
                    <Grid container spacing={isMobile ? 1 : 4}>
                        {productsInfo
                            .filter(p => p.tags.includes('bracelete'))
                            .map(p => (
                                <Grid key={p.id} item md={3} xs={4}>
                                    <ProductCard product={p} />
                                </Grid>
                            ))}
                    </Grid>
                </Section>
                <Section>
                    <Typography className={classes.header} variant="h5">Necklace</Typography>
                    <Grid container spacing={isMobile ? 1 : 4}>
                        {productsInfo
                            .filter(p => p.tags.includes('necklace'))
                            .map(p => (
                                <Grid key={p.id} item md={3} xs={4}>
                                    <ProductCard product={p} />
                                </Grid>
                            ))}
                    </Grid>
                </Section>
                <Section>
                    <Typography className={classes.header} variant="h5">Earring</Typography>
                    <Grid container spacing={isMobile ? 1 : 4}>
                        {productsInfo
                            .filter(p => p.tags.includes('earring'))
                            .map(p => (
                                <Grid key={p.id} item md={3} xs={4}>
                                    <ProductCard product={p} />
                                </Grid>
                            ))}
                    </Grid>
                </Section>
                <Section>
                    <Typography className={classes.header} variant="h5">Accessories</Typography>
                    <Grid container spacing={isMobile ? 1 : 4}>
                        {productsInfo
                            .filter(p =>
                                !p.tags.includes('necklace') &&
                                !p.tags.includes('bracelete') &&
                                !p.tags.includes('earring'))
                            .map(p => (
                                <Grid key={p.id} item md={3} xs={4}>
                                    <ProductCard product={p} />
                                </Grid>
                            ))}
                    </Grid>
                </Section>
            </div>
        </div>
    );
};

export default ProductsPage;
