import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';

import { ProductInfo } from '../../api/products';
import DetailsModal from '../DetailsModal';

type Variant = 'instagram' | 'facebook';

interface Props {
  showDetails: boolean,
  variant: Variant,
  toggleShowDetails: () => void,
  action: (routeUrl: string) => void,
  collection: Array<ProductInfo>,
  isFirst?: boolean,
  isLast?: boolean,
  setIdx: React.Dispatch<React.SetStateAction<number>>
  idx: number,
}

const useStyles = makeStyles((_: Theme) => createStyles({
  root: {
    width: '100%',
  },
  backdrop: {
    width: '100%',
  },
  actionButton: {
    position: 'absolute',
    left: 10,
    bottom: 20,
  },
  detailsButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  button: {
    position: 'absolute',
    backgroundColor: '#FFFFFF55',
    "&:hover": {
      backgroundColor: '#FFFFFF55',
    },
  },
  imgPlaceholder: {
    position: 'relative',
    paddingTop: '100%',
  },
  details: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    color: "#0000",
    '&:hover': {
      background: "#0005",
      color: "#FFFF",
    }
  },
  collectionControl: {
    position: 'absolute',
    top: '50%',
    width: '100%',
  },
}));

const ProductCard = ({ showDetails, variant, toggleShowDetails, action, collection, idx, setIdx }: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { name, type, imageUrl } = collection[idx];
  const classes = useStyles();

  return (
    <div className={classes.root} >
      <div className={classes.imgPlaceholder}>
        <img className={classes.backdrop} style={{ position: 'absolute', height: '100%', top: 0, overflow: 'clip' }} src={imageUrl} alt={`${type} - ${name} design`} />
        <Button className={classes.details} onClick={() => { setModalOpen(true) }}>
          <Typography variant='h6' component='h2'>
            {name}
          </Typography>
        </Button>
        <DetailsModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          product={collection[idx]}
        />
      </div>
    </div>
  );
};

export default ProductCard;
